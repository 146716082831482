
export class BFServerResponse<T> {

    public success: boolean;
    public errorMessage: string;
    public statusCode: number;
    public data: T;

    constructor() {
        
    }
}