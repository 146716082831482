/**
 * Global colors used by stylus
 */
export class BFColors {
    public static white: string = '#fff';
    public static offWhite: string = '#f7f7f7';
    public static black: string = '#000';
    public static offBlack: string = '#363636';
    public static blackHover: string = '#222';
    public static darkGrey: string = '#9B9B9B';
    public static grey: string = '#D8D8D8';
    public static lightGrey: string = '#f8f8f8';
    public static border: string = '#ccc';
    public static green: string = '#2CBC45';
    public static yellow: string = '#FFED00';
    public static gold: string = '#e6c996';
    public static lightGold: string = '#fffdf4';
    public static orange: string = '#f5a623';
    public static lightRed: string = '#fbe6e6';
    public static red: string = '#FF594B';
    public static lightBlue: string = '#edf5ff';
    public static blue: string = '#1B75DD';

    public static getByName(name: string = ''): string {
        return BFColors[name] || '';
    }

    /**
     * To both be able to input the name of any of our brandColors but also rgb, rgba, or hex and the output will be selected accordingly.
     * @param color 
     */
    public static getColor(color: string): string {
        const brandColor: string = BFColors.getByName(color);

        // If this color is a brand color;
        if (brandColor) {
            return brandColor;
        }

        // Hex/RGB value inputed
        if (color && (color.indexOf('#') === 0 || color.indexOf('rgb') === 0)) {
            return color;
        }

        return '';
    }
}