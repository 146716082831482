import * as _ from 'underscore';

/**
 *
 * @export
 * @param {number} [milliseconds=0]
 * @param {any} [options={}]
 * @returns
 */
export function BFThrottle(milliseconds: number = 0) {
    return function(
        target: any,
        propertyKey: string,
        descriptor: PropertyDescriptor
    ) {
        const originalMethod = descriptor.value;
        descriptor.value = _.throttle(originalMethod, milliseconds);
        return descriptor;
    };
}
