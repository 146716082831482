import { BFStringUtils } from '../string/bfString.utils';


export class BFStyleUtils {

    private static vendorCache: any = {}; // To only do vendor check once.

    /**
     * 
     * @param styles 
     * @param x 
     * @param y 
     */
    public static translateXY(styles: any, x: number, y: number) {
        // browser detection and prefixing tools
        const transform = BFStyleUtils.getVendorPrefixedName('transform');
        const backfaceVisibility = BFStyleUtils.getVendorPrefixedName('backfaceVisibility');
        const hasCSSTransforms = !!BFStyleUtils.getVendorPrefixedName('transform');
        const hasCSS3DTransforms = !!BFStyleUtils.getVendorPrefixedName('perspective');
        const ua = window.navigator.userAgent;
        const isSafari = (/Safari\//).test(ua) && !(/Chrome\//).test(ua);

        if (hasCSSTransforms) {
            if (!isSafari && hasCSS3DTransforms) {
            styles[transform] = `translate3d(${x}px, ${y}px, 0)`;
            styles[backfaceVisibility] = 'hidden';
            } else {
            styles[BFStringUtils.toCamelCase(transform)] = `translate(${x}px, ${y}px)`;
            }
        } else {
            styles.top = `${y}px`;
            styles.left = `${x}px`;
        }
    }

    /**
     * Get correct vendor prefixed css-property
     * @param property
     */
    public static getVendorPrefixedName(property: string): string {
        const name = BFStringUtils.toCamelCase(property);

        if (!this.vendorCache[name]) {
            const prefix = BFStyleUtils.getPrefix();
            const testStyle = document.createElement('div').style;

            if (testStyle[prefix.css + property] !== undefined) {
                this.vendorCache[name] = prefix.css + property;
            } else if (testStyle[property] !== undefined) {
                this.vendorCache[name] = property;
            }
        }

        return this.vendorCache[name];
    }

    /**
     * Get Prefix
     * http://davidwalsh.name/vendor-prefix
     */
    private static getPrefix(): any {
        const styles = window.getComputedStyle(document.documentElement!, '');
        const pre = (Array.prototype.slice.call(styles).join('').match(/-(moz|webkit|ms)-/))![1];
        const dom = ('WebKit|Moz|MS|O').match(new RegExp('(' + pre + ')', 'i'))![1];

        return {
            dom,
            lowercase: pre,
            css: `-${pre}-`,
            js: pre[0].toUpperCase() + pre.substr(1)
        };
    }

}
