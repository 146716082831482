/**
 * Global font sizes used by stylus
 */
export class BFFontSizes {
    public static tiny: number = 8;
    public static small: number = 10;
    public static button: number = 11;
    public static default: number = 12;
    public static medium: number = 14;
    public static large: number = 18;
    public static extraLarge: number = 22;

    public static getByName(name: string = ''): number {
        return BFFontSizes[name];
    }

    public static getAsRem(name: string = ''): string {
        const size: number = BFFontSizes.getByName(name);
        return size ? `${size / 10}rem` : '';
    }

    public static getAsPx(name: string = ''): string {
        const size: number = BFFontSizes.getByName(name);
        return size ? `${size / 10}px` : '';
    }
}
