import * as _ from 'underscore';

/**
 *
 * @export
 * @param {number} [milliseconds=0]
 * @param {any} [options={}]
 * @returns
 */
export function BFDebounce(milliseconds: number = 0, immediate: boolean = false) {
    return function(
        target: any,
        propertyKey: string,
        descriptor: PropertyDescriptor
    ) {
        const originalMethod = descriptor.value;
        descriptor.value = _.debounce(originalMethod, milliseconds, immediate);
        return descriptor;
    };
}
