export class BFNumberUtils {

    /**
     * Round value to a certain deciam
     * @param value value that shall be rounded
     * @param toNearest Passing 0.1 will round 5.133 to 5.1
     */
    public static roundTo(value: number, toNearest: number = 1): number {
        return Math.round(value / toNearest) * toNearest;
    }

    /**
     * Limit a value between to values.
     * @param value
     * @param min
     * @param max
     */
    public static limit (value: number, min: number, max: number): number {
        return Math.min(Math.max(value, min), max);
    }

    /**
     * Get value closest to a certain number
     * @param numbers
     * @param value
     */
    public static closest(numbers: number[] = [], value: number): number {
        let minDiff = 1000;
        let ans: number = numbers[0];

        for (let i = 0; i < numbers.length; i++) {
            const m = Math.abs(value - numbers[i]);
            if (m < minDiff) {
                minDiff = m;
                ans = numbers[i];
            }
        }

        return ans;
    }
}
